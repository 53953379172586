<template>
  <!-- Inline styling to override default calendar settings -->
  <div
    class="ci"
    :class="[cvClasses, mainDivColor]"
    :style="`top: ${top}px; height: ${height - 1}px`"
    @click="handleClick"
  >
    <div class="ci--info" :style="`height: ${AVAILABILITY_ROW_HEIGHT}px;`">
      <div
        class="ci--info--header justify-center"
      >
        <div v-if="isTypeBlock || isTypeFillerBlock || (isBlock && createdByCharterUp)" style="width: 24px">
          <v-tooltip top>
            <template #activator="{ on }">
              <div v-on="on">
                <CRIcon
                  :key="`clear-icon-${item.vehicleTypeId}-${item.startDate}`"
                  view-box="0 0 24 24"
                  :width="24"
                  :height="24"
                >
                  clear
                </CRIcon>
              </div>
            </template>
            <span v-if="item.reasonType">
              {{ item.reasonType.reasonLabel }}
            </span>
          </v-tooltip>
        </div>
        <div v-else-if="isGeneralBlock || isFreeFillerBlock">
          <div v-if="showAvailableVehicles">
            <p class="ci--info--header--center">
              {{ item.availableVehicles }}
            </p>
          </div>
          <div v-else-if="addInCalendarView && inCalendarClicked">
            <CRIcon
              :key="`clear-icon-${item.vehicleTypeId}-${item.startDate}`"
              view-box="0 0 24 24"
              :width="24"
              :height="24"
            >
              clear
            </CRIcon>
          </div>
          <div v-else-if="item.unavailableVehicles" class="ci--info--header">
            <v-tooltip v-if="item.overbookedVehicles" top>
              <template #activator="{ on }">
                <div v-on="on">
                  <p class="ci--info--header--overbooked">
                    + {{ item.overbookedVehicles }}
                  </p>
                </div>
              </template>
              <span>{{ item.overbookedVehicles }} Overbooked</span>
            </v-tooltip>
          </div>
        </div>
        <div v-else-if="isVehicleTypeGeneralBlock">
          <v-badge :color="$cr.theme.red">
            <template v-if="item.overbookedVehicles" #badge>
              <span></span>
            </template>
            <div v-if="item.notOfferedVehicles" class="not-offered-box">
              <p style="margin: 0">
                {{ item.notOfferedVehicles }}
              </p>
            </div>
          </v-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CRIcon from '@/cr/components/CRIcon.vue'
import { AVAILABILITY_ROW_HEIGHT } from '@/utils/availability'
import { DateTime } from 'luxon'
import { truncate } from '@/utils/string'
import { EventBus } from '@/utils/event-bus'

const CHARTERUP_COMPANY_ID = 2

export default {
  components: { CRIcon },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    top: {
      type: Number,
      default: 0,
    },
    classes: {
      type: Array,
      default: () => [],
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    isTypeBlock: {
      type: Boolean,
      default: false,
    },
    isGeneralBlock: {
      type: Boolean,
      default: false,
    },
    isVehicleTypeGeneralBlock: {
      type: Boolean,
      default: false,
    },
    isCompanyFillerBlock: {
      type: Boolean,
      default: false,
    },
    isTypeFillerBlock: {
      type: Boolean,
      default: false,
    },
    isFreeFillerBlock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      AVAILABILITY_ROW_HEIGHT,
      inCalendarClicked: false,
    }
  },
  computed: {
    cvClasses() {
      return 'cv-item ' + this.classes.join(' ')
    },
    mainDivColor() {
      if (this.isTypeBlock) {
        let style = 'type-block-color'
        if (this.addInCalendarView) {
          style = `${style} type-block-color--calendar-add`
        }
        return style
      } else if (this.isTypeFillerBlock) {
        return 'type-filler-block-color'
      } else if (this.isBlock) {
        let style = 'block-color'
        if (this.addInCalendarView && this.createdByCharterUp) {
          if (!this.inCalendarClicked) {
            style = `${style} block-color--calendar-add`
          } else {
            style = 'free-filler-block free-filler-block--calendar-add'
          }
        }
        return style
      } else if (this.isGeneralBlock) {
        if (this.item.availableVehicles) {
          let style = 'general-color--green'
          if (this.addInCalendarView) {
            if (this.inCalendarClicked) {
              style = 'type-block-color type-block-color--calendar-add'
            } else {
              style = `${style} general-color--green--calendar-add`
            }
          }
          return style
        } else {
          let style = 'general-color--red'
          if (this.addInCalendarView) {
            if (this.inCalendarClicked) {
              style = 'type-block-color type-block-color--calendar-add'
            } else {
              style = `${style} general-color--red--calendar-add`
            }
          }
          return style
        }
      } else if (this.isVehicleTypeGeneralBlock) {
        let style = 'vehicle-type-general-block'
        if (this.addInCalendarView) {
          style = `${style} vehicle-type-general-block--calendar-add`
        }
        return style
      } else if (this.isCompanyFillerBlock) {
        return 'company-filler-block-color'
      } else if (this.isFreeFillerBlock) {
        let style = 'free-filler-block'
        if (this.addInCalendarView) {
          if (this.inCalendarClicked) {
            style = 'block-color block-color--calendar-add'
          } else {
            style = `${style} free-filler-block--calendar-add`
          }
        }
        return style
      } else if (this.isReservation) {
        let style = 'reservation-color'
        if (this.addInCalendarView) {
          style = `${style} reservation-color--calendar-add`
        }
        switch (this.item.reservations.length) {
          case 0:
            return ''
          case 1:
            return `${style} reservation-color--1`
          case 2:
            return `${style} reservation-color--2`
          default:
            return `${style} reservation-color--3`
        }
      }
      return ''
    },
    isReservation() {
      return (
        !this.isBlock &&
        !this.isGeneralBlock &&
        !this.isVehicleTypeGeneralBlock &&
        !this.isTypeBlock &&
        !this.isTypeFillerBlock &&
        !this.isCompanyFillerBlock &&
        !this.isFreeFillerBlock
      )
    },
    addInCalendarView() {
      return this.item.addInCalendarView || false
    },
    showAvailableVehicles() {
      return (
        this.item.availableVehicles &&
        (!this.addInCalendarView ||
          (this.addInCalendarView && !this.inCalendarClicked))
      )
    },
    height() {
      if (this.item?.height) {
        return this.item.height
      }
      return AVAILABILITY_ROW_HEIGHT
    },
    createdByCharterUp() {
      return this.item.createdByCompanyId === CHARTERUP_COMPANY_ID
    }
  },
  watch: {
    addInCalendarView(value) {
      if (!value) {
        this.inCalendarClicked = false
      }
    },
    item(value) {
      if (value?.isAddedBlock) {
        this.inCalendarClicked = true
      } else {
        this.inCalendarClicked = false
      }
    },
  },
  async mounted() {
    if (this.item?.isAddedBlock) {
      this.inCalendarClicked = true
    }
  },
  methods: {
    handleClick() {
      if (this.isTypeBlock) {
        if (this.addInCalendarView) {
          EventBus.$emit('add-to-delete-list', this.item)
          return
        }
        const component = () =>
          import('@/components/VehicleAvailabilitySidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            mode: 'edit',
            companyId: this.item.companyId,
            availabilityBlock: this.item,
            isTypeBlock: true,
            title: 'Edit Unavailability',
          },
          component,
        })
      } else if (this.isBlock) {
        if (!this.addInCalendarView) {
          const component = () =>
            import('@/components/VehicleAvailabilitySidebar.vue')
          if (this.createdByCharterUp) {
            this.$store.dispatch('app/openSidebarDialog', {
              data: {
                mode: 'edit',
                companyId: this.item.companyId,
                availabilityBlock: this.item,
                isVehicleBlock: true,
                title: 'Edit Unavailability',
              },
              component,
            })
          } else {
            this.$store.dispatch('app/openSidebarDialog', {
              data: {
                mode: 'view',
                companyId: this.item.companyId,
                availabilityBlock: this.item,
                title: `Operator(${this.item.reasonType.reasonLabel})`,
              },
              component,
            })
          }
        } else if (this.createdByCharterUp) {
          EventBus.$emit('add-to-delete-list', this.item)
          return
        }
      } else if (this.isGeneralBlock || this.isVehicleTypeGeneralBlock) {
        if (this.isGeneralBlock && this.addInCalendarView) {
          this.inCalendarClicked = !this.inCalendarClicked
          EventBus.$emit('add-to-add-list', {
            vehicleTypeId: this.item.vehicleTypeId,
            companyId: this.item.companies[0]?.companyId,
            startDate: this.item.startDate.toISODate(),
            endDate: this.item.endDate.toISODate(),
          })
          return
        }
        const component = () =>
          import('@/components/AvailabilityGeneralSidebar.vue')
        this.$store.dispatch('app/openSidebarDialog', {
          data: {
            title: `${
              this.item.vehicleTypeName
            } (${this.item.startDate.toLocaleString(DateTime.DATE_SHORT)})`,
            vehicleTypeId: this.item.vehicleTypeId,
            availableVehicles: this.item.availableVehicles,
            unavailableVehicles: this.item.unavailableVehicles,
            overbookedVehicles: this.item.overbookedVehicles,
            notOfferedVehicles: this.item.notOfferedVehicles,
            offeredReservations: this.item.offeredReservations,
            notOfferedReservations: this.item.notOfferedReservations,
            unassignedReservations: this.item.unassignedReservations,
            assignedReservations: this.item.assignedReservations,
            vehicleBlocks: this.item.vehicleBlocks,
            companies: this.item.companies,
            isVehicleTypeGeneralBlock: this.isVehicleTypeGeneralBlock,
          },
          component,
        })
        return
      } else if (this.isReservation) {
        if (!this.addInCalendarView) {
          if (this.item.reservations?.length === 1) {
            let route = this.$router.resolve({
              name: 'reservation-detail',
              params: { id: this.item.reservations[0].parentReservationId },
            })
            window.open(route.href)
          } else if (this.item.reservations?.length > 1) {
            const component = () =>
              import('@/components/AvailabilityReservationSidebar.vue')
            this.$store.dispatch('app/openSidebarDialog', {
              data: {
                title: `${truncate(
                  this.item.companies[0].name,
                  12
                )} (${this.item.startDate.toLocaleString(
                  DateTime.DATE_SHORT
                )})`,
                vehicle: this.item.vehicle,
                reservations: this.item.reservations,
              },
              component,
            })
          }
        }
      } else if (this.isFreeFillerBlock && this.addInCalendarView) {
        this.inCalendarClicked = !this.inCalendarClicked
        EventBus.$emit('add-to-add-list', {
          vehicleId: this.item.vehicleId,
          vehicleTypeId: this.item.vehicleTypeId,
          companyId: this.item.companies[0]?.companyId,
          startDate: DateTime.fromISO(this.item.startDate).toISODate(),
          endDate: DateTime.fromISO(this.item.endDate).toISODate(),
        })
        return
      }
      return
    },
  },
}
</script>
<style lang="scss" scoped>
.type-block-color {
  background: $input-border-gray !important;
  z-index: 7 !important;
  cursor: pointer;

  &--calendar-add {
    border: 1px dashed $gray-mid-light !important;
  }
  &--calendar-add:hover {
    border: 1px dashed $gray-mid-light !important;
    background: $gray-even-lighter !important;
  }
}
.type-filler-block-color {
  background: $input-border-gray !important;
  pointer-events: none;
  z-index: 5 !important;
}
.block-color {
  margin-top: 1px !important;
  background: $input-border-gray !important;
  cursor: pointer;
  z-index: 7 !important;
  &--calendar-add {
    border: 1px dashed $gray-mid-light !important;
  }
  &--calendar-add:hover {
    border: 1px dashed $gray-mid-light !important;
    background: $gray-even-lighter !important;
  }
}
.reservation-color {
  margin-top: 1px !important;
  z-index: 6;
  &--calendar-add {
    pointer-events: none;
  }
  &--1 {
    background: #d5e5f1 !important;
  }
  &--2 {
    background: #c1d8ea !important;
  }
  &--3 {
    background: $blue-dull !important;
  }
}
.general-color {
  margin-top: 1px !important;
  z-index: 5 !important;
  cursor: pointer;
  &--green {
    background: rgba($green, 0.25) !important;

    &--calendar-add {
      border: 1px dashed $green !important;
    }
    &--calendar-add:hover {
      border: 1px dashed $green !important;
      background: rgba($green, 0.5) !important;
    }
  }
  &--red {
    background: rgba($red, 0.1) !important;
    &--calendar-add {
      border: 1px dashed $red !important;
    }
    &--calendar-add:hover {
      background: rgba($red, 0.25) !important;
    }
  }
}
.vehicle-type-general-block {
  &--calendar-add {
    pointer-events: none;
  }
}
.company-filler-block-color {
  background: $white !important;
  pointer-events: none;
}
.free-filler-block {
  margin-top: 1px !important;
  background: rgba($green, 0.25) !important;
  cursor: pointer;
  z-index: 7 !important;
  &--calendar-add {
    border: 1px dashed $green !important;
  }
  &--calendar-add:hover {
    border: 1px dashed $green !important;
    background: rgba($green, 0.5) !important;
  }
}
.not-offered-box {
  width: 28px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px $red;
  border-radius: 4px;
  background: rgba($red, 0.1);
}
.ci {
  border: none;
  padding: 0;
  &--info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: 1px;
    padding: 5px;

    &--header {
      margin: 0 0 0 0;
      font-size: 14px;
      line-height: 1.2;
      display: flex;
      flex-direction: row;
      align-items: center;

      &--block {
        margin: 0;
        padding-top: 0;
        padding-left: 5px;
      }

      &--reservation {
        margin-bottom: 0;
        color: black;
      }

      &--general {
        display: flex;
        align-items: center;
        padding-right: 12px;
      }

      &--center {
        margin-bottom: 0;
        justify-items: center;
        width: 100%;
      }
      &--overbooked {
        margin-bottom: 0;
        font-weight: 600;
        color: $red;
      }
    }

    &--secondary {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
    }
  }
  &--info:hover {
    cursor: pointer;
  }
}
::v-deep .v-badge__badge {
  top: -3px !important;
  right: -4px !important;
  height: 10px !important;
  width: 10px !important;
}
</style>
