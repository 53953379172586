<template>
  <div>
    <GarageSidebar
      :active="active"
      :row-data="rowData"
      :close-handler="closeHandler"
      :mode="mode"
    />
    <BaseTableWrapper
      :table-init="tableInit"
      :enable-export="false"
      :initial-filters="initialFilters"
      style="padding-top: 0; padding-left: 0; margin-left: 15px"
      remove-padding
    >
      <div class="page-header"></div>
    </BaseTableWrapper>
  </div>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import GarageActions from '@/components/GarageActions.vue'
import GarageSidebar from '@/components/GarageSidebar.vue'
import garages from '@/services/garages'
import Vue from 'vue'

export default {
  components: {
    BaseTableWrapper,
    GarageSidebar,
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    isAffiliateView: {
      type: Boolean,
    },
  },
  data() {
    return {
      isAdmin: false,
      active: false,
      mode: 'view',
      refreshFn: null,
      initialFilters: [],
      tableInit: {
        id: 'affiliate-garages-list',
        detailKeyId: 'companyId',
        tableId: 'affiliate-garages_tv_view',
        areStatusFiltersShowing: true,
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        forceRefresh: 0,
        columns: [],
        collection: 'Garages',
        enableColumnConfig: false,
        enableExport: false,
        enableSavedViews: false,
        additionalQueries: null,
        action: (params) => garages.getGarages(params),
      },
    }
  },
  async mounted() {
    let columns = [
      {
        _t_id: '0925db7d-0f28-4e5b-b39e-1e2602bb83c9',
        prop: '/',
        text: 'Actions',
        component: this.createComponent(GarageActions, this.viewSidebar),
        sort: false,
        filter: false,
        detail: false,
        type: 'actions',
      },
      {
        _t_id: 'da886d8b-b22a-4f88-81ab-23c23e4fea8f',
        prop: 'garageName',
        text: 'Garage Name',
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'name',
        defaultSort: true,
      },
      {
        _t_id: '42aa4dbb-a662-4215-b483-da3734869e88',
        prop: [
          'address/street1',
          'address/street2',
          'address/city',
          'address/state',
          'address/postalCode',
          'address/country',
        ],
        text: 'Address',
        type: 'text',
        filterType: 'contains',
        sortProp: 'address/street1',
      },
      {
        _t_id: '25582262-17e1-44df-9797-fc6c5bb7f54f',
        prop: ['address/city', 'address/state'],
        text: 'City, State',
        computedText: this.cityStateComputedText,
        type: 'text',
        filterType: 'contains',
        sortProp: 'address/city',
      },
      {
        _t_id: '7868f2f2-a7da-4ff8-8b49-e8bf828c5b7b',
        prop: 'noOfVehicles',
        text: 'Number of Vehicles',
        type: 'number',
        filterType: 'eq',
        sortProp: 'noOfVehicles',
        defaultSort: true,
      },
      {
        _t_id: '748e1a50-a77c-11ef-b864-0242ac120002',
        prop: 'companyId',
        text: 'Company ID',
        sort: false,
        filter: true,
        type: 'number',
        filterType: 'eq',
        detail: false,
        defaultHidden: true,
      }
    ]

    this.tableInit.columns.push(...columns)

    if (this.isAffiliateView) {
      this.tableInit.additionalQueries = 'isAffiliateDetailsView=true'
      this.initialFilters = [
        {
          column: columns.find(
            (column) => column._t_id === '748e1a50-a77c-11ef-b864-0242ac120002'
          ),
          value: this.company.companyId.toString(),
          hideOnFilterBar: true,
        }
      ]
    }
  },
  methods: {
    cityStateComputedText(row, column, action) {
      const { address } = column
      return `${address.city}, ${address.state}`
    },
    viewSidebar(data) {
      this.active = true
      this.rowData = data
      this.mode = 'view'
    },
    closeHandler(refreshQuery) {
      if (refreshQuery) {
        this.tableInit.forceRefresh = 1 + Math.random()
      }
      this.active = false
    },
    createComponent(component, handler) {
      return Vue.component('WrappedActions', {
        props: {
          row: {
            type: Object,
            default: () => ({}),
          },
          handleAction: {
            type: Function,
            default: () => null,
          },
          rowProps: {
            type: Object,
            default: () => ({}),
          },
        },
        render(createElement) {
          return createElement(component, {
            props: {
              row: this.row,
              handleAction: this.handleAction,
              rowProps: this.rowProps,
              handler: () => {
                handler(this.row)
              },
            },
          })
        },
      })
    },
  },
}
</script>

<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}

::v-deep .layout.status-filters.row.justify-space-between {
  justify-content: end;
}
</style>
